const arrow = document.getElementById('arrow-to-vp')
const VPTop = document.getElementById('value-proposition').getBoundingClientRect().top
const scrollToVP = () => {
  window.scrollTo({ top: VPTop - 50, behavior: 'smooth' })
}
/* arrow.addEventListener('click', scrollToVP) */

// GA
const submitHero = document.getElementById('submit-hero')
const submitLastCall = document.getElementById('last-call-submit')

submitHero.addEventListener('click', () => {
  const { analytics } = window
  analytics.track('Home - Hero - Bouton Estimation')
})

submitLastCall.addEventListener('click', e => {
  const { analytics } = window
  analytics.track('Home - Last call - Bouton Estimation')
})
